.hero-section {
  justify-content: center;
  text-align: center;
  padding-top: 150px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 85px;
  background: url("/public/images/card_bg.svg") no-repeat right;
  background-size: cover;
}

.avatar {
  width: 30%;
  min-width: 100px;
  max-width: 140px;
}

h1 {
  font-weight: 400;
  font-size: 1.7em;
  letter-spacing: 3px;
}

p.thin {
  font-weight: 200;
  font-size: 1em;
  letter-spacing: 1px;
}
p.wrapped {
  margin: auto;
  max-width: 280px;
  line-height: 24px;
}

.green-text {
  color: var(--accent-color);
}
.social {
  margin-top: 10px;
}

.social > a > img {
  padding: 20px;
}