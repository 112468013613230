#contact {
  background-color: var(--secondary-bg-color);
}

.contact-section {
  padding: 20px 0;
}

input:focus,
textarea:focus,
textarea,
input {
  margin: 10px;
  outline: none;
  width: auto;
  height: auto;
  font-size: 18px;
  font-weight: 300;
  padding: 14px 20px;
  border-radius: 8px;
  border: none;
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
}
textarea {
  resize: none;
}
.form-container {
  margin: 0px -10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-input-row {
  display: flex;
  flex-flow: row wrap;
}

.form-input-row > input {
  flex-grow: 1;
  flex-basis: 200px;
}
.btn-submit,
.btn-submit:focus {
  box-shadow: 4px 4px 4px #000;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--accent-color) !important;
}
.btn-submit:active {
  box-shadow: none;
}


.toast {
  position: fixed;
  width: 200px;
  right: 20px;
  border-radius: 8px;
  padding: 16px 20px;
  top: 80px;
}

.info {
  background-color: #2F80ED;
}

.error {
  background-color: #ff5555;
}

.success {
  background-color: #38d993;
}

.toast-visible {
  display: block;
}

.toast-hidden {
  display: none;
}
