.project-item-container {
  background: var(--primary-bg-color);
  height: 110px;
  width: 100%;
  margin: 12px;
  display: flex;
  box-sizing: border-box;
  align-content: center;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 2px 2px 2px #111;
}

.project-icon {
  height: 100%;
  width: auto;
  border-radius: 50%;
}

p {
  margin: 0px;
}

.projectPlatform {
  opacity: 0.6;
  font-weight: thin;
  color: var(--accent-color);
}

p.projectPlatform > span {
  font-weight: 600;
  opacity: 1;
  color: var(--primary-text-color);
}
.project-detail-container {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
