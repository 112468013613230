/* :root {
  --primary-bg-color: #ffffff;
  --secondary-bg-color: #F8F9FA;
  --primary-text-color: #000;
  --secondary-text-color: #3e3e3e;
  --accent-color: #38d993;
}  */

:root {
  --primary-bg-color: #303030;
  --secondary-bg-color: #212121;
  --primary-text-color: #fff;
  --secondary-text-color: #fff;
  --accent-color: #38d993;
}

html {
  scroll-behavior: smooth;
}
body {
  background: #212121;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
  color: var(--primary-text-color);
  cursor: pointer;
}
h2 {
  letter-spacing: 2px;
}