.App {
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
}

.container {
  box-sizing: border-box;
  padding: 20px 20px;
}

@media only screen and (min-width: 768px) {
  .container {
    padding: 20px 10%;
  }
}

.hide-mobile {
  display: none;
}

h2 {
  font-weight: 400;
  font-size: 1.2em;
  color: var(--accent-color);
}

footer {
  width: 100%;
  padding: 0px 20px;
  text-align: center;
}
