nav {
  background-color: #1a1a1a;
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0;
  box-shadow: 0px 1px 1px var(--secondary-bg-color);
}

div.nav-container {
  width: 100%;
  display: flex;
  flex: 1 1 0;
  justify-content: space-between;
  align-content: center;
}

div.nav-container > * {
  width: 100%;
}

.menuIcon {
  width: auto;
  height: 18px;
  cursor: pointer;
}

.logo {
  width: auto;
  height: 23px;
}

.menuLinks > ul {
  background-color: var(--secondary-bg-color);
  border-radius: 8px;
  box-shadow: 2px 2px 10px #111;
  padding: 22px 40px;
  margin: 28px 0px;
  list-style-type: none;
}

.menuLinks > ul > li {
  font-size: 20px;
  padding: 20px 0;
}
