.disclaimer-container {
  background: var(--primary-bg-color);
  height: 100%;
  width: auto;
  margin: 20px;
  box-sizing: border-box;
  align-content: center;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 2px 2px 2px #111;
}
