.skill-list-container {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -12px;
}

.skill-list-container > div {
  background-color: var(--secondary-bg-color);
  box-shadow: 2px 2px 2px #111;
  padding: 20px;
  margin: 12px;
  flex-grow: 1;
  border-radius: 8px;
  flex-basis: 300px;
}

.skill-item-container {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
}

.skill-item-container > img {
  width: 16px;
}

.skill-item-container > p {
  min-width: 140px;
}

.skill-section {
  margin: 12px 0px;
}

h4 {
  text-align: center;
}