.project-section {
    background-color:var(--secondary-bg-color);
    padding: 20px 0px;
}
.project-list-container {
    display: flex;
    flex-flow: row wrap;
    margin: 0px -12px;
}
.project-list-container > * {
    flex-grow: 1;
    flex-basis: 300px;
}